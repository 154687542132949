import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ComprovacaoAnexo } from './comprovacao-anexo.';
import { DialogModule } from 'primeng/dialog';
import { AnexarArquivoComponent } from '../anexar-arquivo/anexar-arquivo.component';
import { ToastService } from '@mpng/spa';
import { Router } from '@angular/router';
import { RadioButton } from 'primeng/primeng';
import { ConfirmationService } from 'primeng/api';
import { ComprovacaoAuxilioSaudeService } from './comprovacao-auxilio-saude.service';

@Component({
  selector: 'app-comprovacao-auxilio-saude-aviso',
  templateUrl: './comprovacao-auxilio-saude-aviso.html'
})
export class ComprovacaoAuxilioSaudeAvisoComponent implements OnInit {

  
   private ano: string;
  constructor(private dialog: MatDialog,
   private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioSaudeService,
    private router: Router
  ) { }

  
  buscar() {
  }

  ngOnInit() {
 

    this.comprovacaoAuxilioEducacaoService.userDetailLogado().subscribe(
         result => {
            if (result.nomePerfil == "Servidor"){
                 document.getElementById("divAviso").style.display = "block";
            }
         }
    );

   this.comprovacaoAuxilioEducacaoService.viewAnoComprovacao().subscribe(
      result => {
        this.ano = result;
      }
    );


  }

  


}
