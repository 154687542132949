import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppInfo } from '@mpng/spa';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
    public appInfo: AppInfo =
        {
            name: "Comprovação de Auxílio Saúde",
            context: "/cauxsaude",
            icon: "mp-icon mp-prh",
            //spinner: {bdColor:"rgba(51, 51, 51, 0.8)", size:"medium",color:"#0d2db7",type:"timer"} //Caso queira mudar o spinner padrão
        };
        
    title = 'app';
    //constructor(public toast: ToastsManager, vRef: ViewContainerRef) {
    constructor(private router: Router) {
    }

    ngAfterViewInit() {
        this.router.navigate(['/login']);
    }
}
