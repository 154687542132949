import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RadioButton, MessageService } from 'primeng/primeng';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@mpng/spa';
import { ComprovacaoAuxilioSaudeService } from './comprovacao-auxilio-saude.service';
import { ComprovacaoFilterDTO } from './comprovacao-filterdto';
import { ComprovacaoAuxilioSaudeAprovacaoDetalheComponent } from '../aprovacao-detalhe/comprovacao-auxilio-saude-aprovacao-detalhe.component';


interface StatusComprovacao {
  name: string;
  code: string;
}

interface VisualList {
  name: string;
  code: string;
}

@Component({
  selector: 'app-comprovacao-auxilio-saude-consulta',
  styles: [
    `.search-results {
			height: 67%;
			overflow: scroll;
    }
    
   .search-results-nao-enviado {
      overflow: scroll;
    }
    
    `
  ],
  templateUrl: 'comprovacao-auxilio-saude-consulta.html',
})



export class ComprovacaoAuxilioSaudeConsultaComponent implements OnInit {

  constructor(private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioSaudeService, 
              private messageService: MessageService,
              private route: ActivatedRoute, 
              private toastService: ToastService) { }
  
  @ViewChild("auxAprovacao") auxAprovacao: ComprovacaoAuxilioSaudeAprovacaoDetalheComponent;
  
  private comprovacaoFilterDTO: ComprovacaoFilterDTO;
  private totalRegisters: number;
  private listComprovacao: any[] = [];
  private listComprovacaoGrid: any[] = [];
  selectedStatusComprovacao: StatusComprovacao;
  selectedVisualList: VisualList;
  statusComprovacaoArray: StatusComprovacao[];
  visualListArray: VisualList[];
  ngOnInit() {
    this.comprovacaoFilterDTO=new ComprovacaoFilterDTO();
    this.totalRegisters = 0;
    this.statusComprovacaoArray = [
      { name: '.::Selecione::.', code: '0', },
      { name: 'Documentos não enviados', code: '1' },
      { name: 'Documentos Enviados', code: '2' },
      { name: 'Com pendência', code: '3' },
      { name: 'Pendência em análise', code: '4' },
      { name: 'Recibo de comprovação enviado', code: '5' },
   ];

    this.visualListArray = [
      { name: 'Grid', code: 'G' },
      { name: 'Card', code: 'C' },
    ];

    this.selectedVisualList = this.visualListArray[0];
    this.selectedStatusComprovacao = this.statusComprovacaoArray[0];
    this.comprovacaoFilterDTO.page = 0;
 }

  onScroll() {
    this.comprovacaoFilterDTO.page++;
    this.preencheGrid();
  }

  desabilitaPesquisaData():boolean{
       return (this.selectedStatusComprovacao.code=='0'||  this.selectedStatusComprovacao.code=='1');
  }

  preencheGrid() {
      this.comprovacaoAuxilioEducacaoService.findComprovacao(this.comprovacaoFilterDTO).subscribe(
      result => {
        console.log("result",result);
        this.totalRegisters = result.totalRegisters;
        this.listComprovacaoGrid = result.registers;
        for (let x of result.registers) {
          this.listComprovacao.push(x);
        }
        if (this.listComprovacao.length == 0) {
          this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:"A pesquisa não retornou dados."});
          //this.toastService.showInfo(("A pesquisa não retornou dados."));
        }
      }
    );
  }

  detalharAprovacao(comprovacaoView:any){
    this.auxAprovacao.startTelaAprovacao(comprovacaoView,this);
  }
  /**
   * 
   */
  pesquisar() {
    if (this.comprovacaoFilterDTO.ano ==null) {
      this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:"Informe o ano da comprovação."});
      return false;
    }
    this.comprovacaoFilterDTO.page=0;
    this.comprovacaoFilterDTO.opcaoEnvio=this.selectedStatusComprovacao.code
    this.listComprovacao = [];
    this.preencheGrid();
  }

 downloadRelatorioComprovacao() {
    this.comprovacaoAuxilioEducacaoService.downloadRelatorioComprovacao(this.comprovacaoFilterDTO).subscribe(
      result => {
        var link = document.createElement('a');
        link.setAttribute("type", "hidden");
        link.download = result.headers.get('fileName');
        link.href = window.URL.createObjectURL(result.body);
        document.body.appendChild(link);
        link.click();
      }
    );
  }

  paginate(event) {
    this.comprovacaoFilterDTO.page = event.page;
    this.preencheGrid();
  }

}
