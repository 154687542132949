import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';
import { CdkTableModule } from '@angular/cdk/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PanelModule } from 'primeng/panel'
import { CardModule } from 'primeng/card';
import { DataViewModule } from 'primeng/dataview';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import {
  CalendarModule,
  DialogModule,
  DataTableModule,
  SharedModule,
  InputTextModule,
  EditorModule,
  AutoCompleteModule,
  CheckboxModule,
  RadioButtonModule,
  FileUploadModule,
  DropdownModule,
  ConfirmDialogModule,
  MessagesModule,
  TabViewModule,
  AccordionModule,
} from 'primeng/primeng';
import { FormsModule } from '@angular/forms';
import { MpMessageWithUploadModule, MpPdfViewerModule, MpFileUploadModule, FileUploadService } from '@mpng/spa';
import { AnexarArquivoModule } from '../anexar-arquivo/anexar-arquivo.module';
import { ComprovacaoAuxilioSaudeComponent } from './comprovacao-auxilio-saude.component';
import { ComprovacaoAuxilioSaudeRoutingModule } from './comprovacao-auxilio-saude.routing.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
//import { ComprovacaoAuxilioEducacaoPeriodoReferenciaComponent } from './comprovacao-auxilio-educacacao-periodo-referencia.component';
import {InputMaskModule} from 'primeng/inputmask';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ComprovacaoAuxilioSaudeAvisoComponent } from './comprovacao-auxilio-saude-aviso.component';
import {FieldsetModule} from 'primeng/fieldset';
import { ComprovacaoAuxilioSaudeService } from './comprovacao-auxilio-saude.service';
import { ComprovacaoBenfView } from './comprovacao-benefview';
import { ComprovacaoAuxilioSaudeListAnexoComponent } from '../list-anexo/comprovacao-auxilio-saude-listanexo.component';
import { ComprovacaoAuxilioSaudeAnexarComprovComponent } from '../anexar-comprovante/comprovacao-auxilio-saude-anexarcomprov.component';
import { ComprovacaoAuxilioEducacaoListagemBeneficiarioComponent } from '../list-beneficiario/comprovacao-auxilio-educacao-listagem-beneficiario.component';
import { ComprovacaoAuxilioSaudeConsultaComponent } from './comprovacao-auxilio-saude-consulta.component';
import { QueryList } from '@angular/core/src/render3';
import { ComprovacaoAuxilioSaudeAprovacaoDetalheComponent } from '../aprovacao-detalhe/comprovacao-auxilio-saude-aprovacao-detalhe.component';
import { ComprovacaoAuxilioEducacaoListPendenciaComponent } from '../list-pendencia/comprovacao-auxilio-educacao-listpendencia.component';
import { ComprovacaoAuxilioEducacaoReciboComprovacaoComponent } from '../recibo-comprovacao/comprovacao-auxilio-educacao-recibocomprovacao.component';
import {ToastModule} from 'primeng/toast';
import { ComprovacaoAuxilioSaudePeriodoReferenciaComponent } from './comprovacao-auxilio-saude-periodo-referencia.component';



@NgModule({
  imports: [
    InfiniteScrollModule,
    BrowserModule,
    ComprovacaoAuxilioSaudeRoutingModule,
    CommonModule,
    DialogModule,
    PanelModule,
    MpPdfViewerModule,
    CardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    RadioButtonModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatDialogModule,
    HttpModule,
    DropdownModule,
    DataTableModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatListModule,
    MatStepperModule,
     AnexarArquivoModule,
    CheckboxModule,
    MatCheckboxModule,
    InputTextModule,
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    CalendarModule,
    EditorModule,
    CheckboxModule,
    TableModule,
    PaginatorModule,
    SharedModule,
    AutoCompleteModule,
    MpMessageWithUploadModule,
    FileUploadModule,
    MpPdfViewerModule,
    MpFileUploadModule,
    CheckboxModule,
    MatCardModule,
    MessagesModule,
    PanelModule,
    InputMaskModule,
    OverlayPanelModule,
    FieldsetModule,
    TabViewModule,
    AccordionModule,
    ToastModule,
    DeviceDetectorModule.forRoot()
  ],
  exports: [ComprovacaoAuxilioSaudeComponent,ComprovacaoAuxilioSaudeAvisoComponent,ComprovacaoAuxilioSaudeAnexarComprovComponent,ComprovacaoAuxilioEducacaoListagemBeneficiarioComponent],
  declarations: [
    ComprovacaoAuxilioSaudeComponent,
    ComprovacaoAuxilioSaudePeriodoReferenciaComponent,
    ComprovacaoAuxilioSaudeAvisoComponent,
    ComprovacaoAuxilioSaudeAnexarComprovComponent,
    ComprovacaoAuxilioSaudeListAnexoComponent,
    ComprovacaoAuxilioEducacaoListagemBeneficiarioComponent,
    ComprovacaoAuxilioSaudeConsultaComponent,
    ComprovacaoAuxilioSaudeAprovacaoDetalheComponent,
    ComprovacaoAuxilioEducacaoListPendenciaComponent,
    ComprovacaoAuxilioEducacaoReciboComprovacaoComponent,
    ComprovacaoAuxilioSaudeListAnexoComponent

  ],
  entryComponents: [
    ComprovacaoAuxilioSaudeAvisoComponent,
    ComprovacaoAuxilioSaudeAnexarComprovComponent  
  ],

  providers: [ComprovacaoAuxilioSaudeService, ConfirmationService, FileUploadService],
})
export class ComprovacaoAuxilioSaudeModule { }
