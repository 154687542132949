import { Component, OnInit, ViewChild, ViewChildren, Input } from '@angular/core';

import { ToastService } from '@mpng/spa';
import { Router } from '@angular/router';
import { MatDialog, MatSlideToggle } from '@angular/material';
//import { StringifyOptions } from 'querystring';
import { ResponseContentType, Http, RequestOptions, Headers, Response } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { ComprovacaoAuxilioSaudeService } from '../comprovacao-auxilio-saude/comprovacao-auxilio-saude.service';
import { ComprovacaoAuxilioSaudeAnexarComprovComponent } from '../anexar-comprovante/comprovacao-auxilio-saude-anexarcomprov.component';
import { ComprovacaoBenfView } from '../comprovacao-auxilio-saude/comprovacao-benefview';


import { QueryList } from '@angular/core';


@Component({
  selector: 'app-comprovacao-auxilio-educacao-listagem-beneficiario',
  templateUrl: 'comprovacao-auxilio-educacao-listagem-beneficiario.html',
})
export class ComprovacaoAuxilioEducacaoListagemBeneficiarioComponent implements OnInit {

  private listViewBenef: any[];
  @Input() tela:string ;
  @Input() comprovacaoView: any;
  private  idsBenefPendente: number[]=[];
  @ViewChildren(ComprovacaoAuxilioSaudeAnexarComprovComponent) arrayAnexarComprovantes:QueryList<ComprovacaoAuxilioSaudeAnexarComprovComponent>;
 

  /**
   * 
   * @param dialog 
   * @param comprovacaoAuxilioSaudeService 
   * @param toastService 
   * @param router 
   */
  constructor(private dialog: MatDialog,
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioSaudeService,
    private toastService: ToastService,
    private router: Router,
    private http: Http
  ) { }

  /**
   * 
   */
  ngOnInit() {
    console.log("this.tela"+this.tela);
     if (this.tela=="usuario"){
        this.buildViewlistBeneficiario();
     }
     if (this.tela=="operador"){
       this.buildViewlistBeneficiarioByMatricAndPeriodoRef();
     }
  }

  public getListViewBenef():any[]{
    return  this.listViewBenef;
  }
  
  /**
   * 
   */
  buildViewlistBeneficiario() {
    this.comprovacaoAuxilioEducacaoService.viewlistBeneficiario().subscribe(
      result => {
          this.listViewBenef = result;
          this.buidListPendente();
      }
    );
  }

 buidListPendente(){
    for (let benef of  this.listViewBenef) {
      if (benef.comprovacaoBenefViewDTO.pendente == 'S'){ 
          this.idsBenefPendente.push(benef.comprovacaoBenefViewDTO.id);
       }
    }
 }

 public getListIdsBenefPendente():number[]{
    return this.idsBenefPendente;
 }

  /**
   * 
   */
  buildViewlistBeneficiarioByMatricAndPeriodoRef() {
    this.comprovacaoAuxilioEducacaoService.viewlistBeneficiarioByMatricAndPeriodoRef(this.comprovacaoView.cdMatricula,this.comprovacaoView.idPeriodoReferencia).subscribe(
      result => {
          this.listViewBenef = result;
          this.buidListPendente();
      }
    );
  }

  
 public existeAlteracao():boolean{
    let arrayComprpovAuxComp: ComprovacaoAuxilioSaudeAnexarComprovComponent[] = this.arrayAnexarComprovantes.toArray();
    for (let comprovAuxComp of  arrayComprpovAuxComp) {
      if (comprovAuxComp.existeAlteracao()){
        return true;
      }
    }
     return false;
  }

}




