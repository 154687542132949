import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@mpng/spa';
import { WelcomeComponent } from './welcome/welcome.component';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/comprovacao-auxilio-saude-aviso',
        pathMatch: 'full'
    },

    {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Login', icon: 'mdi mdi-login', noMenu:true }
    }
];
@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { useHash: true})],
    exports: [RouterModule],
    declarations: []
})
export class AppRoutingModule { }
