import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { FileUploadService, ToastService } from '@mpng/spa';
import { RequestOptions, ResponseContentType } from '@angular/http';
import { ComprovacaoAnexo } from './comprovacao-anexo.';
import { ComprovacaoBenfView } from './comprovacao-benefview';
import { ComprovacaoBenfDTO } from './comprovacao-benefdto';
import { ComprovacaoFilterDTO } from './comprovacao-filterdto';
import { PeriodoReferenciaDTO } from './periodo-referenciadto';


@Injectable()
export class ComprovacaoAuxilioSaudeService {

    /**
     * 
     * @param http 
     * @param fileUploadService 
     */
    constructor(private http: HttpClient, private fileUploadService: FileUploadService) { }

    viewComprovacao(): Observable<any> {
        const url = `/api/view/comprovacao/`;
        return this.http.get<any>(url);
    }

    viewComprovacaoById(): Observable<any> {
        const url = `/api/view/ComprovacaoById/`;
        return this.http.get<any>(url);
    }

    viewComprovacaoRenovacaoAnexo(comprovacaoBenfView: ComprovacaoBenfView, renovacao: Boolean): Observable<any> {
        const url = !renovacao ? `/api/view/comprovacaoAnexo/` : '/api/view/renovacaoAnexo/';
        return this.http.post(url, comprovacaoBenfView);
    }

    saveComprovacaoAnexo(comprovacaoBenfDTO: ComprovacaoBenfDTO, filesDocs: File[], renovacao: Boolean): Observable<any> {
        console.log("comprovacaoBenfDTO.id==>" + comprovacaoBenfDTO.id);
        const url = !renovacao ? `/api/comprovacaoBenef/saveComprovacao/` : `/api/comprovacaoBenef/saveRenovacao/`;
        return this.fileUploadService.postMessageAndUploadModel(url, { other: comprovacaoBenfDTO, message: "", files: filesDocs });
    }

    viewAnexoByID(idAnexo: number) {
        const url = `/api/comprovacaoAnexo/findByID/` + idAnexo;
        return this.http.get<any>(url);
    }

    viewlistBeneficiario(): Observable<any> {
        const url = `/api/view/listBeneficiario/`;
        return this.http.get<any>(url);
    }

    viewlistBeneficiarioByMatricAndPeriodoRef(matricula: string, idPeriodoRef: number): Observable<any> {
        const url = `/api/view/listBeneficiarioByMatricAndPeriodoRef/` + matricula + "/" + idPeriodoRef;
        return this.http.get<any>(url);
    }


    viewAnoComprovacao(): Observable<any> {
        var url = '/api/periodoReferencia/anoComprovacao/';
        return this.http.get<any>(url);
    }

    userDetailLogado(): Observable<any> {
        const url = `/api/userDetailLogado`;
        return this.http.get<any>(url);
    }

    existePeriodoRefAberto() {
        var url = '/api/periodoReferencia/existePeriodoRefAberto';
        return this.http.get<any>(url);
    }

    enviarComprovacao(): Observable<any> {
        var url = '/api/comprovacaoBenef/enviarComprovacao';
        return this.http.get<any>(url);
    }

    viewfindComprovacao(comprovacaoBenfView: ComprovacaoBenfView, renovacao: Boolean): Observable<any> {
        const url = !renovacao ? `/api/view/comprovacaoAnexo/` : '/api/view/renovacaoAnexo/';
        return this.http.post(url, comprovacaoBenfView);
    }

    findComprovacao(comprovacaoFilterDTO: ComprovacaoFilterDTO): Observable<any> {
        var url = '/api/comprovacaoWeb/findComprovacao';
        return this.http.post(url, comprovacaoFilterDTO);
    }

    downloadRelatorioComprovacao(comprovacaoFilterDTO: ComprovacaoFilterDTO): Observable<any> {
        var url = '/api/comprovacaoWeb/downloadRelatorioComprovacao';
        return this.http.post(url, comprovacaoFilterDTO, { responseType: 'blob', observe: 'response' });
    }


    saveSituacaoAnexo(lisAnexo: any[]): Observable<any> {
        const url = `/api/comprovacaoAnexo/saveSituacaoAnexo`;
        return this.http.post(url, lisAnexo);
    }

    downloadComprovacaoAnexo(idAnexo: number): Observable<any> {
        var url = '/api/comprovacaoAnexo/downloadComprovacaoAnexo/' + idAnexo;
        return this.http.get(url, { responseType: 'blob', observe: 'response' });
    }

    salvarComprovacaoPendente(idComprovacaoWeb: number, messagePendenciaComprovacao: string, idComprovacaoBenefPendentes: number[], files: File[]) {
        const url = `/api/exigenciaComprovacaoWeb/salvarComprovacaoPendente/` + idComprovacaoWeb + "/" + idComprovacaoBenefPendentes;
        return this.fileUploadService.postMessageAndUploadModel(url, { other: "", message: messagePendenciaComprovacao, files: files });
    }


    concluirComprovacao(idComprovacaoWeb: number, messageComprovacao: string, files: File[]) {
        const url = `/api/comprovacaoWeb/concluirComprovacao/` + idComprovacaoWeb;
        return this.fileUploadService.postMessageAndUploadModel(url, { other: "", message: messageComprovacao, files: files });
    }


    findExigenciaByIdComprovacao(idComprovacaoWeb: number): Observable<any> {
        const url = `/api/exigenciaComprovacaoWeb/findByIdComprovacao/` + idComprovacaoWeb;
        return this.http.get<any>(url);
    }

    findExigenciaById(id: number): Observable<any> {
        const url = `/api/exigenciaComprovacaoWeb/findById/` + id;
        return this.http.get<any>(url);
    }


    findComprovacaoWebById(id: number): Observable<any> {
        const url = `/api/comprovacaoWeb/findComprovacaoWebById/` + id;
        return this.http.get<any>(url);
    }

    findPeriodoComprovacaoByAno(ano: String) {
        var url = `/api/periodoReferencia/findPeriodoComprovacaoByAno`;
        if (ano != undefined) {
            url = url + "/" + ano;
        }
        return this.http.get<any>(url);
    }

    savePeriodoReferencia(periodoReferenciaDTO: PeriodoReferenciaDTO): Observable<any> {
        var url = '/api/periodoReferencia/save';
        return this.http.post(url, periodoReferenciaDTO);
    }

    saveReabertura(periodoReferenciaDTO: PeriodoReferenciaDTO): Observable<any> {
        var url = '/api/periodoReferencia/saveReabertura';
        return this.http.post(url, periodoReferenciaDTO);
    }

    deletePeriodoReferencia(id: number): Observable<any> {
        var url = '/api/periodoReferencia/delete/'+id;
        return this.http.get<any>(url);
    }

}
