import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Inject, PLATFORM_ID, APP_ID, AfterViewChecked, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { AnexarArquivoComponent } from './../anexar-arquivo/anexar-arquivo.component';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { RadioButton, MessageService } from 'primeng/primeng';
import { ToastService } from '@mpng/spa';
import { Location, isPlatformBrowser } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { DOCUMENT } from '@angular/platform-browser';
import { ComprovacaoAuxilioSaudeService } from '../comprovacao-auxilio-saude/comprovacao-auxilio-saude.service';
import { ComprovacaoAuxilioEducacaoListagemBeneficiarioComponent } from '../list-beneficiario/comprovacao-auxilio-educacao-listagem-beneficiario.component';
import { ComprovacaoAuxilioSaudeConsultaComponent } from '../comprovacao-auxilio-saude/comprovacao-auxilio-saude-consulta.component';


@Component({
    selector: 'app-comprovacao-auxilio-saude-aprovacao-detalhe',
    templateUrl: 'comprovacao-auxilio-saude-aprovacao-detalhe.html',
})


export class ComprovacaoAuxilioSaudeAprovacaoDetalheComponent implements OnInit {
    constructor(public dialog: MatDialog, private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioSaudeService,
        private toastService: ToastService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
    ) { }

    private tela: string="operador";
    protected  visibleTela:boolean;
    protected  isDataFechamentoVencida:boolean;
    private comprovacaoView: any;
    private atendeComprovacao:string;
//TO DO
    private textoReciboPadrao: string = '<p>Prezado(a),</p><p>&nbsp;</p><p>A comprova&ccedil;&atilde;o 2023&nbsp;do seu aux&iacute;lio-sa&uacute;de foi realizada, conforme recibo em anexo. Observe atentamente os lan&ccedil;amentos feitos.</p><p>&nbsp;</p><p>Att.,</p><p>Ger&ecirc;ncia de Processos e Benef&iacute;cios de Servidores</p><p>Diretoria de Recursos Humanos</p>';
  /*Alterado para a compravação 2019*/
    // private textoReciboPadrao: string = '<p>Prezado(a),</p><p>&nbsp;</p><p>A comprova&ccedil;&atilde;o 2018&nbsp;do seu aux&iacute;lio-sa&uacute;de foi realizada, conforme recibo em anexo. Observe atentamente os lan&ccedil;amentos feitos.</p><p>&nbsp;</p><p>Informo, ainda, que a altera&ccedil;&atilde;o de valor do seu aux&iacute;lio-sa&uacute;de foi realizada a contar do m&ecirc;s de XXXXXXXX de 201X e repercutir&aacute; no pagamento dos benef&iacute;cios do m&ecirc;s de XXXXXXXXXXX de 201X, que ocorrer&aacute; no sexto dia &uacute;til de XXXXXXXXXXXXX de 20XX (constar&aacute; do contracheque de XXXXXXXXXXXXXX de 20XX), com os devidos retroativos (se houver).</p><p>&nbsp;</p><p>Att.,</p><p>&nbsp;</p><p>Ger&ecirc;ncia de Processos e Benef&iacute;cios de Servidores</p>';
    private textoReciboComprovacao: string;
    private messagePendencia:string
    private telaAnterior:ComprovacaoAuxilioSaudeConsultaComponent



    @ViewChild("auxListBenef") auxListBenef: ComprovacaoAuxilioEducacaoListagemBeneficiarioComponent;
    @ViewChild("anexoPendencia") anexoPendencia: AnexarArquivoComponent;
    @ViewChild("anexoReciboComprovacao") anexoReciboComprovacao: AnexarArquivoComponent;


    ngOnInit() {
        this.isDataFechamentoVencida=false;
        this.visibleTela=false;
    }

    startTelaAprovacao(comprovacaoView:any,telaAnterior:ComprovacaoAuxilioSaudeConsultaComponent) {
        this.visibleTela=true;
        this.comprovacaoView=comprovacaoView;
        this.findComprovacaoWebById(this.comprovacaoView.idComprovacaoWeb);
        this.messagePendencia="";
        if (this.comprovacaoView.idStatusComprovante=="2" || this.comprovacaoView.idStatusComprovante=="5" ){
            this.atendeComprovacao="S";
        }else{
            this.atendeComprovacao="N";
        }
        this.telaAnterior = telaAnterior;
        console.log("this.atendeComprovacao",this.atendeComprovacao);
        this.toSetScroll();
    }

    exitTelaAprovacao(){
        this.telaAnterior.pesquisar();
        this.visibleTela=false;

    }

    toSetScroll () {
        /*let top = document.getElementById('divTop');
        top.scrollTo(-50,-50);

        if (top !== null) {
          top.scrollIntoView(true);
          top = null;
        }
        window.scroll(0,0);*/
      }

    findComprovacaoWebById(idComprovacaoWeb:number) {
        this.comprovacaoAuxilioEducacaoService.findComprovacaoWebById(idComprovacaoWeb).subscribe(
          result => {
                this.isDataFechamentoVencida=result.periodoReferenciaDTO.dataFechamentoVencida;
                console.log(" result", result);
              if (result.emailComprovacao==null){
                this.textoReciboComprovacao=this.textoReciboPadrao;
              }else{
                  this.textoReciboComprovacao=result.emailComprovacao;
              }
            }
         );

      }




   gerarPendencia() {
        this.confirmationService.confirm({
            message: 'Tem certeza que deseja incluir uma pendência na comprovação ?',
            accept: () => {
                if (this.anexoPendencia.filesToUpload.length > 1) {
                    this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:"Escolha somente um arquivo por pendência."});
                    //this.toastService.showError(("Escolha somente um arquivo por pendência."));
                    return false;
                }
                if (this.messagePendencia == null || this.messagePendencia == '') {
                    this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:"Favor informar o texto da pendência."});
                    //this.toastService.showError(("Favor informar o texto da pendência."));
                    return false;
                }
                if (this.auxListBenef.getListIdsBenefPendente().length == 0) {
                    this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:"Informe qual(is) beneficiário(s) está(ão) pendente(s)."});
                    //this.toastService.showError("Informe qual(is) beneficiário(s) está(ão) pendente(s).");
                    return false;
                }
                this.comprovacaoAuxilioEducacaoService.salvarComprovacaoPendente(this.comprovacaoView.idComprovacaoWeb, this.messagePendencia, this.auxListBenef.getListIdsBenefPendente(), this.anexoPendencia.filesToUpload).subscribe(
                    result => {
                        if (result.errorCode != null) {
                            this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:result.message});
                            //this.toastService.showError(result.message);
                        } else {
                            //this.messageService.add({key: 'tc',sticky:true,severity:'sucess', summary: 'SUCESSO', detail:"Comprovação da pendência salva com sucesso."});
                            this.toastService.showSuccess("Comprovação da pendência salva com sucesso.");
                            this.exitTelaAprovacao();
                        }
                    });
            }
        });
    }


    gerarReciboComprovacao() {
        this.confirmationService.confirm({
            message: 'Tem certeza que deseja concluir o processo de comprovação ?',
            accept: () => {
                if (this.anexoReciboComprovacao.filesToUpload == null || this.anexoReciboComprovacao.filesToUpload.length == 0) {
                    this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:"Impossível concluir a comprovação, pois não foi anexado o recibo de comprovação para o servidor."});
                    //this.toastService.showInfo(("Impossível concluir a comprovação, pois não foi anexado o recibo de comprovação para o servidor."));
                    return false;
                }
                if (this.anexoReciboComprovacao.filesToUpload.length > 1) {
                    this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:"Escolha somente um arquivo para o recibo de comprovação."});
                    //this.toastService.showInfo(("Escolha somente um arquivo para o recibo de comprovação."));
                    return false;
                }
                if (this.auxListBenef.getListIdsBenefPendente().length >0 ) {
                    this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:"Ao gerar o recibo de comprovação, não pode haver beneficiário(s) pendente(s)."});
                    //this.toastService.showError("Informe qual(is) beneficiário(s) está(ão) pendente(s).");
                    return false;
                }
                console.log(this.textoReciboComprovacao);
                if (this.textoReciboComprovacao == null || this.textoReciboComprovacao == "") {
                    this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:"Favor informar o texto da mensagem de envio do Recibo de Comprovação."});
                    //this.toastService.showInfo(("Favor informar o texto da mensagem de envio do Recibo de Comprovação."));
                    return false;
                }
                this.comprovacaoAuxilioEducacaoService.concluirComprovacao(this.comprovacaoView.idComprovacaoWeb,this.textoReciboComprovacao,this.anexoReciboComprovacao.filesToUpload).subscribe(
                    result => {
                        if (result.errorCode != null) {
                          //this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:result.message});
                            this.toastService.showError(result.message);
                        } else {

                            //this.messageService.add({key: 'tc',sticky:true,severity:'sucess', summary: 'SUCESSO', detail:"Comprovação concluída com sucesso."});
                            this.toastService.showSuccess("Comprovação concluída com sucesso.");
                            this.exitTelaAprovacao();
                        }
                    });
            }
        });
    }


}
