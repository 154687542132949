export class PeriodoReferenciaDTO {
    ano:number;
	dataInicio:Date;
	dataFim:Date;
	dataInicioReabertura:Date;
	dataFimReabertura:Date;
	dataFechamentoComprovacao:Date;
	id:number;
    
}
