import { Component, OnInit, Input, ViewChild } from '@angular/core';
//import { Anexo } from '../domain/anexo';
import { MpFileUploadComponent } from '@mpng/spa';

@Component({
  selector: 'anexar-arquivo',
  templateUrl: './anexar-arquivo.component.html',
  styleUrls: ['./anexar-arquivo.component.css']
})
 export class AnexarArquivoComponent implements OnInit {
  @ViewChild("fileUploadComp") fileUploader : MpFileUploadComponent;
  @Input() tituloAnexar: string;

  @Input() filesToUpload: any[] = [];
  anexo: any;
  displayPdf: boolean = false;
  title: string;

  constructor() { }

  ngOnInit() {
  }

  myUploader(arquivo) {

    for (let file of arquivo.files) {
      //            //console.log('File====>:' + file);
      this.filesToUpload.push(file);

    }
    arquivo.files = [];
    console.log(this.filesToUpload);
  }

  showPdf(arquivo) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.title = arquivo.name;
      let dataURI = reader.result;
      let BASE64_MARKER = ';base64,';
      let base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
      let base64 = dataURI.substring(base64Index);
      this.anexo = base64;
      this.displayPdf = true;
    }

    reader.readAsDataURL(arquivo);
  }

  deletePDF(arquivo) {
   const index: number = this.filesToUpload.indexOf(arquivo);
    if (index !== -1) {
      this.filesToUpload.splice(index, 1);
  }    
  }

}
