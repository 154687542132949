import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@mpng/spa';
import { ComprovacaoAuxilioSaudeComponent } from './comprovacao-auxilio-saude.component';
import { ComprovacaoAuxilioSaudePeriodoReferenciaComponent } from './comprovacao-auxilio-saude-periodo-referencia.component';
import { ComprovacaoAuxilioSaudeAvisoComponent } from './comprovacao-auxilio-saude-aviso.component';
import { ComprovacaoAuxilioSaudeConsultaComponent } from './comprovacao-auxilio-saude-consulta.component';




const comprocacaoAuxilioSaudeRoutes: Routes = [



    {
        path: 'comprovacao-auxilio-saude-aviso',
        component: ComprovacaoAuxilioSaudeAvisoComponent,
        canActivate: [AuthGuardService],
    },
    
    {
        path: 'comprovacao-auxilio-saude-consulta/:action',
        component: ComprovacaoAuxilioSaudeConsultaComponent,
        canActivate: [AuthGuardService],
        data: {
            title: 'Comprovação Auxílio-Saúde Consulta',
            icon: 'mp-icon mp-comprovacao-auxilio-consulta',
            info: '.',
            roles: ['gestor auxílio saúde','operador'],
        }
    },
    /*{
        path: 'comprovacao-auxilio-saude-consulta-detalhe/:ano/:matricula/:idComprovacaoWeb',
        component: ComprovacaoAuxilioSaudeConsultaDetalheComponent,
        canActivate: [AuthGuardService],
        data: {
            title: 'Comprovação Auxílio-Saúde Consulta Detalhe',
            icon: 'mp-icon mp-comprovacao',
            info: '',
            noMenu: true,
            roles: ['gestor auxílio saúde','operador'],
        },

    },*/
    {
        path: 'comprovacao-auxilio-saude',
        component: ComprovacaoAuxilioSaudeComponent,
        canActivate: [AuthGuardService],
        data: {
            title: 'Comprovação Auxílio-Saúde',
            icon: 'mp-icon mp-comprovacao-auxilio',
            info: '',
            roles: ['servidor'],
        }
    },
    {
        path: 'comprovacao-auxilio-saude-periodo-referencia',
        component: ComprovacaoAuxilioSaudePeriodoReferenciaComponent,
        canActivate: [AuthGuardService],
        data: {
            title: 'Comprovação Auxílio-Saúde Período de Referência',
            icon: 'mp-icon mp-periodo',
            info: '',
            roles: ['gestor auxílio saúde'],
        }
    },

];


@NgModule({
    imports: [RouterModule.forChild(comprocacaoAuxilioSaudeRoutes)],
    exports: [RouterModule],
    declarations: []
})
export class ComprovacaoAuxilioSaudeRoutingModule { }
