import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
//import { StringifyOptions } from 'querystring';
import { ResponseContentType, Http, RequestOptions, Headers, Response } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { RadioButton, MessageService } from 'primeng/primeng';
import { AnexarArquivoComponent } from '../anexar-arquivo/anexar-arquivo.component';
import { ComprovacaoAuxilioSaudeService } from '../comprovacao-auxilio-saude/comprovacao-auxilio-saude.service';
import { ToastService } from '@mpng/spa';
import { ComprovacaoFilterDTO } from '../comprovacao-auxilio-saude/comprovacao-filterdto';


@Component({
  selector: 'app-comprovacao-auxilio-saude-listanexo',
  templateUrl: 'comprovacao-auxilio-saude-listanexo.html',
})
export class ComprovacaoAuxilioSaudeListAnexoComponent {


  private anexo: any;
  private title: string;
  private displayPdf: boolean = false;
  @Input() listAnexo: any[];
  selectedIdsExclusao: number[];
  selectedIdsAnalisado: number[];
  selectedIdsDownload: number[];
  @Input() editExclusao: boolean = false;
  @Input() tela: string;
  @Input() dataComprovacao: Date;
  private existeAlteracao: boolean = false;

  /**
   * 
   * @param dialog 
   * @param comprovacaoAuxilioSaudeService 
   * @param toastService 
   * @param router 
   */
  constructor(private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioSaudeService, private messageService: MessageService) { }


  viewAnexoByID(id: number) {
    this.comprovacaoAuxilioEducacaoService.viewAnexoByID(id).subscribe(
      result => {
        this.anexo = result.conteudo;
       // console.log("anexo",this.anexo );
        this.displayPdf = true;
        this.title = result.nome;
      }
    );
  }


  isPodeExcluir(dateAnexo:Date):boolean{
    //console.log("dateAnexo",dateAnexo);
   // console.log("dataComprovacao",this.dataComprovacao);
    if (this.dataComprovacao==null){
        return true;
    }
    if (dateAnexo>this.dataComprovacao){
     // console.log("entrou aqui");
      return true;
    }else{
      //console.log("entrou aqui nao");
      return false;
    }
  }

  updateExisteAlteracao() {
    this.existeAlteracao = true;
  }

  existeDownload():boolean{
    return this.selectedIdsDownload!=null && this.selectedIdsDownload.length>0
  }


  saveSituacaoAnexo() {
    for (let anexo of this.listAnexo) {
      if (anexo.inAnalisadoBoolean == true) {
        anexo.inAnalisado = 'S';
      } else {
        anexo.inAnalisado = 'N';
      }
    }
    this.comprovacaoAuxilioEducacaoService.saveSituacaoAnexo(this.listAnexo).subscribe(
      result => {
        if (result!.errorCode != undefined) {
          this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:result.message});
          
        } else {
          this.messageService.add({key: 'tc',sticky:true,severity:'success', summary: 'SUCESSO', detail:"Dados salvo com sucesso"});
          this.existeAlteracao = false;
        }
      }
    );

  }


  downloadComprovacaoAnexo() {
    for (let idAnexoDownload of this.selectedIdsDownload) {
      this.comprovacaoAuxilioEducacaoService.downloadComprovacaoAnexo(idAnexoDownload).subscribe(
        result => {
          var link = document.createElement('a');
          link.setAttribute("type", "hidden");
          link.download = result.headers.get('fileName');
          link.href = window.URL.createObjectURL(result.body);
          document.body.appendChild(link);
          link.click();
        }
      );
    }
  }

}








