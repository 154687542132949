import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
//import { StringifyOptions } from 'querystring';
import { ResponseContentType, Http, RequestOptions, Headers, Response } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { RadioButton } from 'primeng/primeng';
import { AnexarArquivoComponent } from '../anexar-arquivo/anexar-arquivo.component';
import { ComprovacaoAuxilioSaudeService } from '../comprovacao-auxilio-saude/comprovacao-auxilio-saude.service';
import { ToastService } from '@mpng/spa';
import { ComprovacaoFilterDTO } from '../comprovacao-auxilio-saude/comprovacao-filterdto';


@Component({
  selector: 'app-comprovacao-auxilio-educacao-listpendencia',
  templateUrl: 'comprovacao-auxilio-educacao-listpendencia.html',
})
export class ComprovacaoAuxilioEducacaoListPendenciaComponent  implements OnInit{

  private existeAlteracao: boolean = false;
  private anexo: any;
  private title:string;
  @Input() idComprovacaoWeb:number;
  private displayPdf: boolean = false;
  @Input() listExigenciaComprov: any[]=[];
  
 

 



/**
     * 
     */
    ngOnInit() {
      if (this.idComprovacaoWeb==null){
        return;
      }
      this.comprovacaoAuxilioEducacaoService.findExigenciaByIdComprovacao(this.idComprovacaoWeb).subscribe(
        result => {
            this.listExigenciaComprov = result
        });
    }


  /**
   * 
   * @param dialog 
   * @param comprovacaoAuxilioSaudeService 
   * @param toastService 
   * @param router 
   */
  constructor(private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioSaudeService, private toastService: ToastService) { }


 


 showExigencia(id: number) {
        this.comprovacaoAuxilioEducacaoService.findExigenciaById(id).subscribe(
        result => {
            this.anexo = result.arquivoEmail;
            this.displayPdf = true;
        });
  }

 showHtmlExigencia(id: number) {
      this.comprovacaoAuxilioEducacaoService.findExigenciaById(id).subscribe(
        result => {
            this.anexo = result.pdfEmailExigencia;
            this.displayPdf = true;
       });
 }


  


  

}








