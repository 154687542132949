import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
//import { StringifyOptions } from 'querystring';
import { ResponseContentType, Http, RequestOptions, Headers, Response } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { RadioButton } from 'primeng/primeng';
import { AnexarArquivoComponent } from '../anexar-arquivo/anexar-arquivo.component';
import { ComprovacaoAuxilioSaudeService } from '../comprovacao-auxilio-saude/comprovacao-auxilio-saude.service';
import { ToastService } from '@mpng/spa';
import { ComprovacaoFilterDTO } from '../comprovacao-auxilio-saude/comprovacao-filterdto';


@Component({
  selector: 'app-comprovacao-auxilio-educacao-recibocomprovacao',
  templateUrl: 'comprovacao-auxilio-educacao-recibocomprovacao.html',
})
export class ComprovacaoAuxilioEducacaoReciboComprovacaoComponent {


  private anexo: any;
  private displayPdf: boolean = false;
  @Input() idComprovacaoWeb: number;
  @Input() idStatusComprovacao: number;
  private existeAlteracao: boolean = false;

  /**
   * 
   * @param dialog 
   * @param comprovacaoAuxilioSaudeService 
   * @param toastService 
   * @param router 
   */
  constructor(private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioSaudeService, private toastService: ToastService) { }



  visualizarReciboComprovacao() {
    this.comprovacaoAuxilioEducacaoService.findComprovacaoWebById(this.idComprovacaoWeb).subscribe(
      result => {
          this.anexo = result.reciboComprovacao;
          this.displayPdf = true;
      }
     );
   
  }


  visualizarEmailReciboComprovacao() {
    this.comprovacaoAuxilioEducacaoService.findComprovacaoWebById(this.idComprovacaoWeb).subscribe(
      result => {
          this.anexo = result.pdfMessageEmailComprovacao;
          this.displayPdf = true;
      }
     );
   
  }
  



}








