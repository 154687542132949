import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SpaModule, PipesModule } from '@mpng/spa';

import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComprovacaoAuxilioSaudeModule } from './comprovacao-auxilio-saude/comprovacao-auxilio-saude.module';



@NgModule({
    declarations: [
        AppComponent,
        WelcomeComponent

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        ComprovacaoAuxilioSaudeModule,
        BrowserModule,
BrowserAnimationsModule,
FormsModule,
ReactiveFormsModule,
AppRoutingModule,
SpaModule.forRoot(),
PipesModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
