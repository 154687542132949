import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import {
  MatButtonModule,
  MatIconModule,
  MatTooltipModule
} from '@angular/material';
import { DialogModule,FileUploadModule } from 'primeng/primeng';

import { MpPdfViewerModule, MpFileUploadModule } from '@mpng/spa';
import { AnexarArquivoComponent } from './anexar-arquivo.component';

@NgModule({
  imports: [
    CommonModule,
    MpPdfViewerModule,
    MpFileUploadModule,
    FileUploadModule,
    DialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    FileUploadModule
  ],
  declarations: [AnexarArquivoComponent],
  exports: [AnexarArquivoComponent]
})
export class AnexarArquivoModule { }
