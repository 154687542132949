import { Component, OnInit, ViewChild, Input, QueryList, ViewChildren } from '@angular/core';
import { ToastService } from '@mpng/spa';
import { Router } from '@angular/router';
import { MatDialog, MatSlideToggle } from '@angular/material';
//import { StringifyOptions } from 'querystring';
import { ResponseContentType, Http, RequestOptions, Headers, Response } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { RadioButton, ConfirmationService, MessageService } from 'primeng/primeng';
import { AnexarArquivoComponent } from '../anexar-arquivo/anexar-arquivo.component';
import { ComprovacaoAuxilioSaudeComponent } from '../comprovacao-auxilio-saude/comprovacao-auxilio-saude.component';
import { ComprovacaoAuxilioSaudeService } from '../comprovacao-auxilio-saude/comprovacao-auxilio-saude.service';
import { ComprovacaoBenfView } from '../comprovacao-auxilio-saude/comprovacao-benefview';
import { ComprovacaoBenfDTO } from '../comprovacao-auxilio-saude/comprovacao-benefdto';
import { ComprovacaoAuxilioSaudeListAnexoComponent } from '../list-anexo/comprovacao-auxilio-saude-listanexo.component';
import { ComprovacaoAuxilioEducacaoListagemBeneficiarioComponent } from '../list-beneficiario/comprovacao-auxilio-educacao-listagem-beneficiario.component';


@Component({
  selector: 'app-comprovacao-auxilio-saude-anexarcomprov',
  templateUrl: 'comprovacao-auxilio-saude-anexarcomprov.html',
})
export class ComprovacaoAuxilioSaudeAnexarComprovComponent implements OnInit{

 


  @Input() comprovacaoBenfView:ComprovacaoBenfView;
  @Input() renovacao: Boolean = false;
  @Input() index: number;
  @Input() last: Boolean=false;
  @Input() tela:string ;
  
  private dialogId: string;
  private viewComprovacaoAnexo: any;
  private flagAlteracao:boolean=false;
  private existAnexosDeclaracaoEmpregador:string;
  private flagDisabledComponent:boolean;

  @ViewChild("anexoDoc") anexoDoc: AnexarArquivoComponent;
  @ViewChild("listagemAnexo") listagemAnexo: ComprovacaoAuxilioSaudeListAnexoComponent;
  


 


  ngOnInit(): void {
    this.buildComprovacaoAnexo();
    this.dialogId=this.renovacao?"dialogRenovacao"+this.index:"dialogComprovacao"+this.index ;
  }

  updateFlagAlteracao():void{
    if   (this.flagDisabledComponent==false){
      this.flagAlteracao=true;
    }
  }

  /**
   * 
   * @param dialog 
   * @param comprovacaoAuxilioSaudeService 
   * @param toastService 
   * @param router 
   */
  constructor(private dialog: MatDialog,
    private comprovacaoAuxilioSaudeService: ComprovacaoAuxilioSaudeService,
    //private toastService: ToastService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private http: Http
  ) { }

  processStatusOrPage() {

    this.flagDisabledComponent = true;
    if (this.tela == "operador") {
      this.flagDisabledComponent = true;
    } else {
      if (this.viewComprovacaoAnexo.comprovacaoBenefDTO.comprovacaoWebDTO.statusComprovanteDTO.id == "1") {
        this.flagDisabledComponent = false;
      }
      if (this.viewComprovacaoAnexo.comprovacaoBenefDTO.comprovacaoWebDTO.statusComprovanteDTO.id == "3") {
        if (this.viewComprovacaoAnexo.comprovacaoBenefDTO.isPendente == 'S') {
          this.flagDisabledComponent = false;
        }
      }
    }
  }

  public buildComprovacaoAnexo(){
    this.flagAlteracao=false;
    this.comprovacaoAuxilioSaudeService.viewComprovacaoRenovacaoAnexo(this.comprovacaoBenfView,this.renovacao).subscribe(
      result => {
        this.viewComprovacaoAnexo = result;
        if (this.anexoDoc != null) {
            this.anexoDoc.filesToUpload = [];
        }
        this.processStatusOrPage();
      }
    );


  }
 
public cancel(){
  this.buildComprovacaoAnexo();
}
  
public existeAlteracao():boolean{
  return this.flagAlteracao;
}

  /**
   * 
   * @param comprovacaoBenfDTO 
   */
  prepareSaveComprovacao(comprovacaoBenfDTO: ComprovacaoBenfDTO) {
    comprovacaoBenfDTO.qtdAnexo = this.anexoDoc != null ? this.anexoDoc.filesToUpload.length : 0;
    comprovacaoBenfDTO.selectedIdsExclusao = this.buildSelectedIdsExclusao();
    this.saveComprovacaoAnexo(comprovacaoBenfDTO);
  }


  saveComprovacaoAnexo(comprovacaoBenfDTO: ComprovacaoBenfDTO) {
    this.comprovacaoAuxilioSaudeService.saveComprovacaoAnexo(comprovacaoBenfDTO, this.buildFiles(), this.renovacao).subscribe(
      result => {
        if (result.errorCode != null) {
          this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:result.message});
         // this.toastService.showError(result.message);
        } else {
          this.messageService.add({key: 'tc',sticky:true,severity:'success', summary: 'SUCESSO', detail:"Seus dados foram salvos com sucesso! Não se esqueça de clicar em “Enviar Documentos”, no final da tela, para concluir o procedimento."});
          //this.toastService.showSuccess("Seus dados foram salvos com sucesso");
          this.comprovacaoBenfView.idComprovacaoBenef=result;
          this.buildComprovacaoAnexo();
        }
      }
    );
  }



  private buildFiles(): File[] {
    var files: File[] = [];
    if (this.anexoDoc != null) {
      for (let fileObject of this.anexoDoc.filesToUpload) {
        files.push(fileObject);
      }
    }
      return files;
  }

  private buildSelectedIdsExclusao(): number[] {
    var selectedIdsExclusao: number[] = [];
    if (this.listagemAnexo != null && this.listagemAnexo.selectedIdsExclusao != null) {
      for (let idExclusao of this.listagemAnexo.selectedIdsExclusao) {
        selectedIdsExclusao.push(idExclusao);
      }
    }
    return selectedIdsExclusao;
  }




}
