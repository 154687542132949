import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Comprovacao } from './comprovacao';
import { ComprovacaoAnexo } from './comprovacao-anexo.';
import { DialogModule } from 'primeng/dialog';
import { AnexarArquivoComponent } from '../anexar-arquivo/anexar-arquivo.component';
import { ToastService } from '@mpng/spa';
import { Router } from '@angular/router';
import { RadioButton } from 'primeng/primeng';
import { ConfirmationService } from 'primeng/api';
import { ComprovacaoAuxilioSaudeService } from './comprovacao-auxilio-saude.service';
import { DatePipe } from '@angular/common';
import { PeriodoReferenciaDTO } from './periodo-referenciadto';

@Component({
  selector: 'app-comprovacao-auxilio-saude-periodo-referencia',
  templateUrl: './comprovacao-auxilio-saude-periodo-referencia.html'
})
export class ComprovacaoAuxilioSaudePeriodoReferenciaComponent implements OnInit {

  ano: string;
  dataInicio: Date;
  dataFinal: Date;
  dataInicioReabertura: Date;
  dataFinalReaAbertura: Date;
  anoReferencia:number;
  listPeriodoReferencia: PeriodoReferenciaDTO[];
  idPeriodoRef:number;
  periodoReferenciaDTO: PeriodoReferenciaDTO;



  constructor(private dialog: MatDialog,
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioSaudeService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) { }

  novo() {
    this.periodoReferenciaDTO=new PeriodoReferenciaDTO();
    document.getElementById("divPesquisa").style.display = "none";
    document.getElementById("divSalvar").style.display = "block";
  }

  grid() {
    document.getElementById("divSalvarReabertura").style.display = "none";
    document.getElementById("divSalvar").style.display = "none";
    document.getElementById("divPesquisa").style.display = "block";
    this.findPeriodoComprovacaoByAno();
  }


  editPeridoReabertura(id:number,timerDataInicio: number, timerDataFinal:number, timerDataInicioReabertura: number, timerDataFinalAbertura: number, timerDataFechamento: number,anoReferencia:number){
    this.periodoReferenciaDTO=new PeriodoReferenciaDTO();
    this.periodoReferenciaDTO.id=id;
    this.periodoReferenciaDTO.dataInicio=new Date(timerDataInicio);
    this.periodoReferenciaDTO.dataFim=new Date(timerDataFinal);
    if (timerDataFechamento!=null){
      this.periodoReferenciaDTO.dataFechamentoComprovacao=new Date(timerDataFechamento);
    }
    if (timerDataInicioReabertura!=null){
      this.periodoReferenciaDTO.dataInicioReabertura=new Date(timerDataInicioReabertura);
    }
    if (timerDataFinalAbertura!=null){
     this.periodoReferenciaDTO.dataFimReabertura= new Date(timerDataFinalAbertura);
    }
    this.periodoReferenciaDTO.ano=anoReferencia;
    document.getElementById("divPesquisa").style.display = "none";
    document.getElementById("divSalvarReabertura").style.display = "block";
  }



  buscar() {

  }

  ngOnInit() {
    this.periodoReferenciaDTO=new PeriodoReferenciaDTO();
    this.grid();
  }

  /**
   * 
   * @param ano 
   */
  findPeriodoComprovacaoByAno() {
     this.comprovacaoAuxilioEducacaoService.findPeriodoComprovacaoByAno(this.ano).subscribe(
        result => {
           this.listPeriodoReferencia = result;
           console.log("listPeriodoReferencia", this.listPeriodoReferencia);
        }
    );
  }



  /**
   * 
   * @param idPeriodoReferencia 
   */
  deletePeriodoReferencia(idPeriodoReferencia: number) {
    this.comprovacaoAuxilioEducacaoService.deletePeriodoReferencia(idPeriodoReferencia).subscribe(
      result => {
        console.log("result", result);
        if (result.errorCode != null) {
          this.toastService.showError(result.message);
        } else {
          this.grid();
        }
      }
    );
  }

  savePeriodoReferencia() {
    console.log("this.anoReferencia",this.anoReferencia);
    if (this.periodoReferenciaDTO.dataInicio == undefined || this.periodoReferenciaDTO.dataFim == undefined || this.periodoReferenciaDTO.ano == undefined) {
      this.toastService.showError("Informe a data de início, data final e ano de referência");
      return false;
    }
    this.comprovacaoAuxilioEducacaoService.savePeriodoReferencia(this.periodoReferenciaDTO).subscribe(
      result => {
        if (result.errorCode != null) {
          this.toastService.showError(result.message);
        }else{
          this.ano="";
          this.grid();
        }
      }
    );
  }


  updateReabertura() {
    if (this.periodoReferenciaDTO.dataInicioReabertura == undefined || this.periodoReferenciaDTO.dataFimReabertura == undefined ) {
       this.toastService.showError("Informe a data de início de reabertura e data final de reabertura");
       return false;
    }
   this.comprovacaoAuxilioEducacaoService.saveReabertura(this.periodoReferenciaDTO).subscribe(
      result => {
        if (result.errorCode != null) {
          this.toastService.showError(result.message);
        }else{
          this.ano="";
          this.grid();
        }
      }
    );
  }




}
