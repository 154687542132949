import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ComprovacaoAnexo } from './comprovacao-anexo.';
import { DialogModule } from 'primeng/dialog';
import { AnexarArquivoComponent } from '../anexar-arquivo/anexar-arquivo.component';
import { ToastService } from '@mpng/spa';
import { Router } from '@angular/router';
import { RadioButton } from 'primeng/primeng';
import { ConfirmationService } from 'primeng/api';
import {Message, MessageService} from 'primeng/components/common/api';
import { ComprovacaoAuxilioSaudeService } from './comprovacao-auxilio-saude.service';
import { ComprovacaoBenfView } from './comprovacao-benefview';
import { ComprovacaoAuxilioSaudeAnexarComprovComponent } from '../anexar-comprovante/comprovacao-auxilio-saude-anexarcomprov.component';
import { ComprovacaoAuxilioEducacaoListagemBeneficiarioComponent } from '../list-beneficiario/comprovacao-auxilio-educacao-listagem-beneficiario.component';


@Component({
  selector: 'app-comprovacao-auxilio-saude',
  templateUrl: './comprovacao-auxilio-saude.component.html',
  styleUrls: ['./comprovacao-auxilio-educacao.component.css']
})
export class ComprovacaoAuxilioSaudeComponent implements OnInit {

  @ViewChild("auxListBenef") auxListBenef: ComprovacaoAuxilioEducacaoListagemBeneficiarioComponent;
  

  private viewComprovacao:any;
  private tela: string="usuario";

  constructor(private dialog: MatDialog,
    private comprovacaoAuxilioEducacaoService:ComprovacaoAuxilioSaudeService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
  ) { }


  ngOnInit() {
    this.comprovacaoAuxilioEducacaoService.existePeriodoRefAberto().subscribe(
        result => {
           if (result == false) {
                this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:"Não existe período de referência aberto para comprovação de auxílio-saúde."});
                document.getElementById('divPeriodoComprovacaoAtivo').style.display = 'none';
            }else{
              this.buildViewComprovacao();
            } 
        }
    );   
  }


  buildViewComprovacao(){
  this.comprovacaoAuxilioEducacaoService.viewComprovacao().subscribe(
    result => {
      this.viewComprovacao = result;
    }
  );
 }
  



 
 enviarComprovacao() {
  this.messageService.clear();
  if (this.auxListBenef.existeAlteracao()){
       this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:"Existem alterações não salvas. Salve ou cancele antes de enviar a declaração."});
       return;
  }

  let msg:string=" Atenção! Após enviar a documentação, não será permitido anexar novos documentos ou modificar os documentos enviados. Deseja continuar?";  
  this.confirmationService.confirm({
    key:'dialogConfirmEnvio',
    message: msg,
    accept: () => {
      this.comprovacaoAuxilioEducacaoService.enviarComprovacao().subscribe(
        result => {
          if ( result!.errorCode != undefined) {
            this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:result.message});
          } else {
            this.messageService.add({key: 'tc',severity:'success',sticky:true, summary: 'SUCESSO', detail:"Documentos enviados com sucesso. Confirmação enviada para seu e-mail funcional."});
            this.buildViewComprovacao();
            this.auxListBenef.buildViewlistBeneficiario();
          }
        }
      );
    }
  });
}

}
